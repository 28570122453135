<template>
    <div>
        <LoadArticoli v-if="posts.length > 0" backgroundColor="transparent" />
        <div v-if="posts.length < 1" style="width=100%" class="mt-12 text-center">
            <h1>{{ $t('blog.message.noPosts_1') }}</h1>
            <div class="d-flex flex-column justify-center align-center">
                <v-img data-aos="fade-in" data-aos-delay="500" class="" :max-width="image_size" alt="Obiettivi"
                    src="@/assets/imgs/writing_01.png" />
            </div>
            <h2 data-aos="fade-in" data-aos-delay="1000">{{ $t('blog.message.noPosts_2') }}</h2>
            <h3 data-aos="fade-in" data-aos-delay="2000" class="my-12">{{ $t('blog.message.noPosts_3') }}</h3>
        </div>
        <!-- {{ atLeastOneBlogPost }} -->
    </div>
</template>

<script>
import LoadArticoli from '@/components/LoadArticoli.vue'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init()

export default {
    props: {},
    components: {
        LoadArticoli
    },
    data: () => ({

    }),
    methods: {},
    mounted() {
        this.$store.dispatch("articoli/act_get_article_batch_from_latest")
    },
    created() { },
    computed: {
        image_size() {
            if (this.isSmallMobile) {
                return 200
            } else {
                return 300
            }
        },
        posts() {
            return this.$store.getters["articoli/get_articoli"]
        },
    },
    watch: {}
}
</script>

<style>

</style>